import React from 'react';
import { ViewportProvider } from '@vlinder-web/viewport-provider-react';
import { AppNavigator } from '../AppNavigator';

import { ThemeProvider } from '../providers/theme';
import { Accessories } from './Accessories';

import ReactGA from 'react-ga';
import config from '../config/default-config.json';

export const BootstrapApp = () => {
  console.log(`initializing REACTGA:`, config?.['secret-keys']?.['ga-key']);
  ReactGA.initialize(config?.['secret-keys']?.['ga-key']);
  return (
    <>
      <ThemeProvider>
        <ViewportProvider>
          <AppNavigator />
        </ViewportProvider>
      </ThemeProvider>
      <Accessories />
    </>
  );
};
